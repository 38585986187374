import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
// import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import moment from "moment";

export default function ReactMuiDatePicker(props) {
  const pickerType = props?.pickerType || "DatePicker";

  function handleChange(value) {
    if (props?.updateParentGrid) {
      props.setValue(value._d.toLocaleDateString());
    }
  }
  function selectPicker(type) {
    switch (type) {
      case "DatePicker":
        return (
          <DatePicker
            onChange={handleChange}
            type="datetime-local"
            defaultValue={new Date()}
            value={moment(props.value)}
            slotProps={{ textField: { size: "small" } }}
          />
        );
        {
          /* </DemoItem> */
        }
      // case "DateRangePicker":
      //   return (
      //     <DateRangePicker
      //       value={[moment(props.value[0]), moment(props.value[1])]}
      //       slots={{ field: SingleInputDateRangeField }}
      //       name="allowedRange"
      //     />
      //   );
    }
  }
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DemoContainer
        components={[
          pickerType,
          // 'TimePicker',
          // 'DateTimePicker',
          // 'DateRangePicker',
        ]}
        sx={{ paddingTop: 0 }}
      >
        {selectPicker(pickerType)}
      </DemoContainer>
    </LocalizationProvider>
  );
}
