import Button from "@mui/material/Button";
import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

const CustomCounterButton = (props) => {
  const dispatch = useDispatch();
  const { formEditedValues } = useSelector((state) => state.home);
  const { data, params } = props;
  const [cellValue, setCellValue] = useState(() => {
    if (params) return parseInt(params.value);
    return 0;
  });
  const buttonText = useMemo(
    () => ({
      decrement: data?.cellContext ? "-" : "-1",
      increment: data?.cellContext ? "+" : "+1",
    }),
    []
  );
  const handleDecrement = () => {
    if (data?.cellContext) setCellValue((prev) => prev - 1);
    else if (formEditedValues[`${data.incrementKey}`]) {
      let params = {};
      params[`${data.incrementKey}`] =
        formEditedValues[`${data.incrementKey}`] - 1;
      dispatch({
        type: "FORM_EDITED_VALUES",
        payload: params,
      });
    }
  };
  const handleIncrement = () => {
    if (data?.cellContext) setCellValue((prev) => prev + 1);
    else {
      let params = {};
      params[`${data.incrementKey}`] =
        (formEditedValues[`${data.incrementKey}`] || 1) + 1;
      dispatch({
        type: "FORM_EDITED_VALUES",
        payload: params,
      });
    }
  };
  return (
    <div style={data.style}>
      <label style={data.labelStyle}>{data.label}</label>
      <div
        style={{ display: "flex", overflow: "hidden", ...data.counterStyle }}
      >
        <Button style={data.buttonStyle} onClick={handleDecrement}>
          {buttonText.decrement}
        </Button>
        <input
          style={data.inputStyle}
          type="text"
          value={
            data?.cellContext
              ? cellValue
              : formEditedValues[`${data.incrementKey}`] || data.default_value
          }
        />
        <Button style={data.buttonStyle} onClick={handleIncrement}>
          {buttonText.increment}
        </Button>
      </div>
    </div>
  );
};

export default CustomCounterButton;
