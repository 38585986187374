import { Box } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useDispatch, useSelector } from "react-redux";
import CustomActionButton from "../CustomComponents/CustomActionButton"


const TimeLine = (props) => {
  const { info } = props.info;
  const dispatch = useDispatch();
  // const [checkStatus, setCheckStatus] = useState(0);

  // const handleClick = (value)=>{
  //   if (actionKey) {
  //     setCheckStatus((prev) => (value ? prev + 1 : prev - 1));
  //   }
  // }
  // useEffect(() => {
  //   if (checkStatus === 0 || checkStatus === 1) {
  //     dispatch({
  //       type: "DEPENDENT_COMPONENTS",
  //       payload: { [actionKey]: checkStatus === 0 ? false : true },
  //     });
  //   }
  // }, [checkStatus]);
  return (
    <>
      <Box style={{ "border": "1px solid #C8CED7",
      padding: "2rem 1rem",
      minWidth: `calc(${info?.containerStyle?.width} + 2rem)`,
      ...info?.containerStyle }}>
        <Box style={{  display:"flex", justifyContent:"space-between", alignItems:"center" }}>
            <Box style={{border:"1px solid #1F5995", position:"absolute", minWidth:info?.containerStyle?.width, marginTop:"-5px"}}></Box>
          {info?.timelineItems.map((element, index) => {
            return (
              <>
                <Box style={{display:"flex", flexDirection:"column", justifyContent:"space-between", alignItems:"center", gap: "3rem"}}>
                  <Box style={{fontSize: 15, fontWeight: 600}}>{element.label}</Box>
                  <Box>
                    {/* <FiberManualRecordIcon style={{zIndex:5, color: element.color || "#1F5995", cursor:"pointer"}} onClick={()=>handleClick(1)} /> */}
                    <CustomActionButton data={element.actionButton}/>
                  </Box>
                  <Box style={{fontSize: 14, color: "grey"}}>{element.date}</Box>
                </Box>
              </>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default TimeLine;
