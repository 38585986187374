import { Box } from "@mui/system";
import Text from "../../../utils/Text";

const ColorScale = (props) => {
  const { info } = props.data;
  const Colors = info.colors.join(",");
  const ParentStyle = {
    maxWidth: `calc(${info?.scaleWidth} + 1rem)`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...info?.parentContainerStyle,
  };
  const BoxStyle = {
    minHeight: info.scaleHeight || 10,
    minWidth: info.scaleWidth || 10,
    height: info.scaleHeight || 10,
    width: info.scaleWidth || 10,
    background: `linear-gradient(to right, ${Colors})`,
    borderRadius: 10,
    ...info?.scaleStyle,
  };
  const StartLabelStyle = {
    fontSize: 10,
    color: "grey",
    ...info?.startLabelStyle,
  };
  const EndLabelStyle = {
    fontSize: 10,
    color: "grey",
    ...info?.endLabelStyle,
  };
  const LabelContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "2px",
    ...info?.labelContainerStyle,
  };
  const verticalParent = {
    height: `calc(${info?.scaleWidth} + 1rem)`,
    width: "max-content",
    display: "flex",
    alignItems: "center",
  };
  const verticalBoxStyle = {
    minHeight: info.scaleWidth || 10,
    minWidth: info.scaleHeight || 10,
    height: info.scaleWidth || 10,
    width: info.scaleHeight || 10,
    background: `linear-gradient(to bottom, ${Colors})`,
    borderRadius: 10,
    ...info?.scaleStyle,
  };
  const verticalLabelContainner = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    marginLeft: 2,
    ...info?.labelContainerStyle,
  };
  return (
    <>
      {info.verticalView ? (
        <Box sx={verticalParent}>
          <Box sx={verticalBoxStyle}></Box>
          <Box sx={verticalLabelContainner}>
            {info.startLabel && (
              <Text sx={StartLabelStyle}> {info.startLabel}</Text>
            )}
            {info.endLabel && <Text sx={EndLabelStyle}>{info.endLabel}</Text>}
          </Box>
        </Box>
      ) : (
        <Box sx={ParentStyle}>
          <Box sx={LabelContainerStyle}>
            {info.startLabel && (
              <Text sx={StartLabelStyle}> {info.startLabel}</Text>
            )}
            {info.endLabel && <Text sx={EndLabelStyle}>{info.endLabel}</Text>}
          </Box>
          <Box sx={BoxStyle}></Box>
        </Box>
      )}
    </>
  );
};

export default ColorScale;
