import Button from "@mui/material/Button";
const ButtonWithIcon = (props) => {
  return (
    <>
      {props.data.components.map((ele) => {
        return ele.type === "buttonWithIcon" ? (
          <div>
            {ele.leftIcon ? (
              <>
                <Button variant="text" style={ele.ButtonStyle}>
                  <span style={{ color: "#0055AF", marginRight: "2px" }}>
                    ↩
                  </span>
                  {ele.name}
                </Button>
              </>
            ) : (
              <>
                <Button variant="text" style={ele.ButtonStyle}>
                  {ele.name}
                  <span style={{ color: "0055AF", marginLeft: "2px" }}>↩</span>
                </Button>
              </>
            )}
          </div>
        ) : (
          ""
        );
      })}
    </>
  );
};

export default ButtonWithIcon;
