import { Popper } from "@mui/material";
import { useRef, useState } from "react";
export default function TagChip(props) {
  const { label, color, handleClick, size, info } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl && info);
  const btnRef = useRef(null);
  function getSizeClass(size) {
    switch (size) {
      case "sm": {
        return "tag-chip-sm";
      }
      default: {
        return "tag-chip-xs";
      }
    }
  }

  function onTagEnter(e) {
    setAnchorEl(btnRef.current);
  }
  function onTagLeave(e) {
    setAnchorEl(null);
  }
  function handleClose(e) {
    setAnchorEl(null);
  }
  return (
    <>
      <button
        ref={btnRef}
        className={`tag-chip ${getSizeClass(size)}`}
        style={{ borderColor: color, color }}
        onClick={handleClick}
        onMouseLeave={onTagLeave}
        onMouseEnter={onTagEnter}
      >
        {label}
      </button>
      <Popper
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        placement="top-start"
      >
        <div className="tag-chip-popper">{info}</div>
      </Popper>
    </>
  );
}
