import React from "react";
import CustomButton from "../../../components/CustomButton";
import FilterAltOutlined from "@mui/icons-material/FilterAltOutlined";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
const styles = (theme) => ({
  filtersHeader: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
    float: "right",
    text: "right",
  },
  filtersHeaderTitle: {
    ...theme.typography.fontSizes.sectionHeaderText,
    margin: "0",
    marginRight: 48,
    fontWeight: theme.typography.fontWeight.bold,
  },
});

const useStyles = makeStyles(styles);
const SelectFilterButton = (props) => {
  const dispatch = useDispatch();
  const { dependentInfo } = useSelector((state) => state.home);
  const classes = useStyles();
  const setFiltersExpanded = () => {
    let params = {}
    params[`${props.data.filterName}`] = !dependentInfo[`${props.data.filterName}`]
    dispatch({
      type: "DEPENDENT_COMPONENTS",
      payload: params,
    });
    if(props?.onClick){
      props.onClick()
    }
  };

  return (
    <div className={classes.filtersHeader} style={{...props?.style}}>
      <CustomButton
        isPrimary={true}
        variant="contained"
        height={props.data.hideLabel ? "" :32}
        width={props.data.hideLabel ?"" :154}
        label={props.data.hideLabel ? "" : "Select Filters"}
        startIcon={<FilterAltOutlined />}
        onButtonClick={setFiltersExpanded}
      />
    </div>
  );
};

export default SelectFilterButton;
