import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import SelectRenderer from "./SelectRenderer";
import { useCallback } from "react";

export default function MappedSelectRenderer(props) {
  const [formDetails, setFormDetails] = useState({});
  const [selectedItems, setSelectedItems] = useState(null);
  const [componentInfo] = useState(props.componentInfo);

  const { dropdownMapping } = useSelector((state) => state.home);
  const [optionsList, setOptionsList] = useState([]);
	useEffect(() => {
    const list = props.formMasterValuesData.filter((obj) => {
      if (
        parseInt(obj.parent_id) === (dropdownMapping[componentInfo.mapperKey]||componentInfo?.filter_id)
      ) {
        obj.label = obj.name;
        obj.value = obj.name;
        return obj;
      }
	});

    const defaultOption = props.formMasterValuesData.filter((obj) => {
      if (
        parseInt(obj.parent_id) === (dropdownMapping[componentInfo.mapperKey]||componentInfo?.filter_id)
      ) {
        if (obj.is_default_value === "Y") {
          obj.label = obj.name;
          obj.value = obj.name;
          return obj;
        }
      }
    });

    const details =
      props.formMenuData.filter(
        (obj) =>
          parseInt(obj["categoryid"]) ===
          (dropdownMapping[componentInfo.mapperKey]||componentInfo?.filter_id)
      )[0] || {};
    
    const selected = props.dropdownSelection[
      `${dropdownMapping[componentInfo.mapperKey]||componentInfo?.filter_id}`
    ]
      ? props.dropdownSelection[`${dropdownMapping[componentInfo.mapperKey]||componentInfo?.filter_id}`]
      : defaultOption;
    setOptionsList(list);
    setFormDetails(details);
    setSelectedItems(selected);
  }, [dropdownMapping,componentInfo,props]);
  
  const handleSelection = useCallback(
    (params) => {
      props.handleSelect(
        params,
        componentInfo,
        dropdownMapping[componentInfo.mapperKey]||componentInfo?.filter_id,
        optionsList
      );
    },
    [dropdownMapping, optionsList, componentInfo]
  );
  return (
    <SelectRenderer
      options={optionsList}
      isMandatory={false}
      isMulti={
        componentInfo.isFormFilters
          ? formDetails.type === "single-select"
            ? false
            : true
          : false
      }
      filterLabel={componentInfo.form_label}
      width={
        componentInfo["isWidthNotRequired"]
          ? ""
          : componentInfo["width"]
          ? componentInfo["width"]
          : ""
      }
      updateSelected={handleSelection}
      selectedItems={selectedItems}
      {...componentInfo}
      labelType={componentInfo.labelType}
    />
  );
}
