import { getFormattedValue } from "../../../components/Table/utils";
import CellChips from "./CellChips";
import CellDateRange from "./CellDateRange";
import CellGroupSet from "./CellGroupSet";
import CellInfoTag from "./CellInfoTag";
import CellRendererSelector from "./CellRendererSelector";
import CellTextField from "./CellTextField";
import CellToggle from "./CellToggle";
import CustomCellValueRenderer from "./CustomCellValueRenderer";
import { CustomRendererComponent } from "./CustomRendererComponent";
import RowExpander from "./RowExpander";
import DropdownCellRenderer from "./dropdownCellRender";

function defaultGroupRenderer(params) {
  const children = params.node.allLeafChildren;
  let value = children[0].data[params.colDef.field];
  if (params.colDef.aggFunc) {
    value = params.node.aggData[params.colDef.field];
  }
  if (params.colDef.valueFormatterType) {
    return <>{getFormattedValue(params.colDef.valueFormatterType, value)}</>;
  }
  return <>{value}</>;
}
export default function GroupCellRenderer(params) {
  function getGroupObject(params) {
    switch (params.groupRendererType) {
      case "groupSet":
        return <CellGroupSet {...params} />;
      case "toggle":
        return <CellToggle {...params} />;
      case "rowExpander":
        return <RowExpander {...params} />;
      case "customRenderer":
        return <CustomRendererComponent {...params} />;
      case "dateRange":
        return <CellDateRange {...params} />;
      case "dropdown":
        return <DropdownCellRenderer {...params} />;
      case "colorChips":
        return <CellChips {...params} />;
      case "textField":
        return <CellTextField {...params} />;
      case "cellInfoTag":
        return <CellInfoTag {...params} />;
      case "customCellValue":
        return <CustomCellValueRenderer {...params} />;
      case "empty":
        return <></>;
      default:
        return defaultGroupRenderer(params);
    }
  }
  function getLeafObject(params) {
    switch (params.leafRendererType) {
      case "selector":
        return <CellRendererSelector {...params} />;
      case "textField":
        return <CellTextField {...params} />;
      case "empty":
        return <></>;
      default:
        return <>{params.value}</>;
    }
  }
  return params.node.group ? getGroupObject(params) : getLeafObject(params);
}
