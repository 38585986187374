import React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
function CellChips(props) {
  let val = getValue(props) || "";
  let varInArr = val.split(",");
  const colorMap = {
    red: "error",
    green: "success",
    orange: "warning",
    blue: "info",
    darkblue: "primary",
    purple: "secondary",
  };
  const styleChanges = {
    color: "#ACACAC",
    borderColor: "#ACACAC",
  };
  function getValue (props) {
    const value = (props.node.group && props?.renderForGroup) ? props.node.allLeafChildren[0].data[props.colDef.field] : props.value
    return value
  }
  return (
    <div>
      {val && val.length > 0 && (
        <Stack direction="row" spacing={1}>
          {varInArr.map((el, index) => {
            if (index % 2 === 0) {
              return (
                <Chip
                  key={index}
                  label={el}
                  variant="outlined"
                  style={el === "Not Created" ? styleChanges : {}}
                  color={colorMap[varInArr[index + 1]] || "default"}
                />
              );
            } else {
              return null;
            }
          })}
        </Stack>
      )}
    </div>
  );
}
export default CellChips;
