import React, { useEffect, useState } from "react";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";

const TableColumnsShowHidePopOver = (props) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState(false);
	const [placement, setPlacement] = useState("right");
	const [popOverStyle, setPopOverStyle] = useState("");
	const [title, setTitle] = useState("");

	useEffect(() => {
		const { components = [] } = props?.data;
		let popOverStyle = "";
		components.map((comps) => {
			if (comps && comps.type === "showHideTableColumns") {
				popOverStyle = comps.style;
				if(comps?.title){
					setTitle(comps?.title)
				}
			}
		});
		setPopOverStyle(popOverStyle);
	}, [props.data]);

	const onButtonClick = (e) => {
		setOpen(!open);
		setAnchorEl(e.currentTarget);
		setPlacement(placement);
	};

	const handleApplyClick = (e) => {
		setOpen(!open);
	};
	return (
		<div
			style={{
				display: "flex",
				...popOverStyle,
			}}>
			<Button
				variant="contained"
				style={{
					width: "35px",
					height: "35px",
					marginTop: "5px",
					backgroundColor: "#0055AF",
				}}
				title={title}
				onClick={(e) => onButtonClick(e)}>
				<PreviewOutlinedIcon />
			</Button>
			<Popper
				open={open}
				anchorEl={anchorEl}
				placement={placement}
				transition
				style={{ width: 150 }}>
				{({ TransitionProps }) => (
					<Fade {...TransitionProps} timeout={350}>
						<Paper style={{ padding: "10px" }}>
							<div style={{ display: "flex", flexDirection: "column" }}>
								<div>
									<input type={"checkbox"}></input>
									<label>Channels</label>
								</div>
								<div>
									<input type={"checkbox"}></input>
									<label>Channel %</label>
								</div>
								<div>
									<input type={"checkbox"}></input>
									<label>Channel C %</label>
								</div>
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										marginTop: "10px",
									}}>
									<button
										style={{ width: "75px" }}
										onClick={(e) => handleApplyClick(e)}>
										Apply
									</button>
								</div>
							</div>
						</Paper>
					</Fade>
				)}
			</Popper>
		</div>
	);
};

export default TableColumnsShowHidePopOver;
