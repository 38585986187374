import React, { useState, useEffect, useRef } from "react";
import LoadingOverlay from "../../../components/LoadingOverlay";

export default function XDView(props) {
  const { data } = props;
  const [loader, setLoader] = useState(true);
  const iframeRef = useRef(null);
  useEffect(() => {
    const iframe = iframeRef.current;
    const handleMessage = (event) => {
      if (
        event.origin === "https://www.figma.com" &&
        event.data &&
        event.data.type === "INITIAL_LOAD"
      ) {
        setLoader(false);
      }
    };
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  return (
    <LoadingOverlay
      loader={loader}
      className={"impact-plan-smart-them" + " impact-loading-overlay"}
      overlayStyle={{
        background: "rgba(255, 255, 255, 1)",
        borderRadius: 0,
      }}
    >
      <div>
        <div className="page-content">
          <iframe
            ref={iframeRef}
            width="1440"
            height="800"
            src={data.url}
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </LoadingOverlay>
  );
}
