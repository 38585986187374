/* eslint-disable react/prop-types */
import React from "react";
import moment from "moment";
import { getWkStDt, getWkEdDt } from "../helpers";

import styles from "../index.module.css";

function HeaderCell({ header, stDt, enDt, width, onClick, idx }) {
  return (
    <div className={`${styles["header-cell"]}`} style={{ width: "20%" }}>
      <div
        onClick={
          onClick &&
          (() => {
            onClick(idx);
          })
        }
      >
        <span
          className={`${onClick && styles["header-title"]}`}
          style={{ fontSize: "12px" }}
        >
          {header}
        </span>
      </div>
      <div>
        <span style={{ fontSize: "12px" }}>{stDt}</span>
        {enDt && (
          <>
            <span> - </span>
            <span style={{ fontSize: "12px" }}>{enDt}</span>
          </>
        )}
      </div>
    </div>
  );
}

export default function Header({
  yr,
  mapping,
  fstMoOfYr,
  headerViewType,
  onHeaderClick,
  filteredEventsCount,
}) {
  const headerCellViews = {
    month: () => (
      <>
        {mapping.map((wks, idx) => (
          <HeaderCell
            key={idx}
            idx={idx}
            header={moment()
              .month(fstMoOfYr + idx)
              .format("MMM")}
            onClick={onHeaderClick}
            stDt={getWkStDt(yr, wks[0]).format("DD MMM")}
            enDt={getWkEdDt(yr, wks[wks.length - 1]).format("DD MMM")}
            width={`${100 / mapping.length}%`}
          />
        ))}
      </>
    ),
    week: () => (
      <>
        {mapping.map((wk, idx) => (
          <HeaderCell
            key={idx}
            idx={wk}
            onClick={onHeaderClick}
            header={`Week ${wk}`}
            stDt={getWkStDt(yr, wk).format("DD MMM")}
            enDt={getWkEdDt(yr, wk).format("DD MMM")}
            width={`${100 / mapping.length}%`}
          />
        ))}
      </>
    ),
    day: () => (
      <>
        {mapping.map((day, idx) => (
          <HeaderCell
            key={idx}
            header={moment(day).format("ddd")}
            stDt={moment(day).format("DD MMM")}
            width={`${100 / mapping.length}%`}
          />
        ))}
      </>
    ),
  };
  return (
    <div className={`${styles["header-row"]}`}>
      {" "}
      {headerCellViews[headerViewType]()}{" "}
    </div>
  );
}
