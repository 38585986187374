import { IconButton, InputBase, Paper } from "@mui/material";
import { Box } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";

const SearchBar = (props) => {
  const { data } = props;
  return (
    <Box
      sx={{
        display: {
          xs: "none",
          md: "block",
        },
      }}
    >
      <Paper
        component="form"
        sx={{
          p: 0,
          display: "flex",
          alignItems: "center",
          width: data?.style?.width || "100%",
          height: data?.style?.height || "30px",
        }}
      >
        <InputBase
          sx={{
            ml: 1,
            flex: 1,
            fontSize: data?.style?.fontSize || "14px",
            height: data?.style?.height || "10x",
          }}
          placeholder={data?.placeholder || "Search"}
          inputProps={`search`}
          onChange={(e) => () => {}}
        />
        <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
    </Box>
  );
};

export default SearchBar;
