import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import CustomActionButton from "./CustomActionButton";
import IA from '../../../assets/ia.png'
const useStyles = makeStyles({
    parentContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 15,
        padding: 10,
        alignItems: 'flex-start'
    },
    cardItem: {
        minWidth: "20rem",
        border: '1px solid #E2E5EA',
        padding: 10,
        borderRadius: 5
    },
    cardHeading: {
        paddingLeft: 9,
        fontWeight: 600,
        fontSize: 14
    },
    icon:{
        width: "1.5rem",
        float: "left",
        marginTop: '-1%',
        borderRadius: '50%',
        marginRight: 5
    },
    rowContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 8,
        padding: 8,
        borderRadius: 5
    },
    itemValue: {
        fontWeight: 600,
        fontSize: 14,
        marginTop: 3
    },
    bottomActionContainer: {
        marginTop: 3,
        display: "flex",
        alignItems: 'center'
    },
    bottomLabel: {
        color: '#0055AF',
        fontSize: 16,
        fontWeight: 600,
        marginLeft: "auto"
    },
    label: {
        fontSize: 14,
    }
})
const SimulationResultCards = (props) => {
  const classes = useStyles();
  const { info } = props.data;
  return (
    <>
      <Box className={classes.parentContainer}>
        {info.map((cardElement) => {
          return (
            <>
            <Box className={classes.cardItem}>
                {cardElement.icon && <img src={IA}  className={classes.icon} />}
                {cardElement.heading && <Typography className={classes.cardHeading}>{cardElement.heading} </Typography>}
                {cardElement?.rowData?.map((rowElement) => {
                    return(
                        <>
                        <Box className={classes.rowContainer} sx={{border:(rowElement.border || '1.5px solid #0055AF'),}}>
                            {rowElement?.rowItems?.map((rowItem) => {
                                return (
                                    <>
                                    <Box>
                                        {rowItem.label && <Typography className={classes.label}>{rowItem.label}</Typography>}
                                        {rowItem.value && <Typography className={classes.itemValue}>{rowItem.value}</Typography>}
                                    </Box>
                                    </>
                                )
                            })}
                        </Box>
                        </>
                    )
                })}
                <Box className={classes.bottomActionContainer}>
                    {cardElement.bottomActionButton && <CustomActionButton data={cardElement.bottomActionButton} />}
                    {cardElement.bottomLabel && <Typography className={classes.bottomLabel}>{cardElement.bottomLabel}</Typography>}
                </Box>
            </Box>            
            </>
          )
        })}
      </Box>
    </>
  );
};

export default SimulationResultCards;
