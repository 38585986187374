import { useState } from "react";
import PlaceholderImage from "../../../assets/placeholder.png";
import { useNavigate } from "react-router-dom";

const CellImageField = (props) => {
  const [activeState, setActiveState] = useState(true);
  const navigate = useNavigate();
  let defaultImage;
  let toggleImage;
  if (props?.uniqueKey === "grocery-price") {
    defaultImage = props.data[props.mappingKey] || "placeholder";
    if (props?.toggleMappingKey !== undefined) {
      toggleImage = props.data[props.toggleMappingKey];
    } else {
      toggleImage = defaultImage;
    }
  }
  return (
    <div>
      {props?.uniqueKey === "grocery-price" ? (
        <div style={props.style}>
          <img
            width={
              props.imageWidth || props.data[`${props.colDef.field}_image_text`]
                ? "60%"
                : "80%"
            }
            src={require(`../../../assets/images/price/${
              activeState ? defaultImage : toggleImage
            }.png`)}
            alt=""
            onClick={() => setActiveState(!activeState)}
          />
        </div>
      ) : (
        <div style={props.style}>
          <img
            width={
              props.imageWidth || props.data[`${props.colDef.field}_image_text`]
                ? "60%"
                : "80%"
            }
            src={require(`../../../assets/images/assort/${props.value}.${props?.imgFormat||'png'}`)}
            alt=""
            onClick={()=>{
              if(props["action"]){
                if(props.action["actionName"] === "navigate"){
                  navigate(
                    "/" +
                      window?.location?.pathname?.split("/")[1] +
                      "/" +
                      window?.location?.pathname?.split("/")[2] +
                      props.action.path
                  );
                }
              }
            }}
          />
          {props.data[`${props.colDef.field}_image_text`] && (
            <div>{props.data[`${props.colDef.field}_image_text`]}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default CellImageField;
