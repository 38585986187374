import { IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SnackBar from "../../../components/SnackBar";
import { useEffect, useState } from "react";
import ComponentResolver from "../Resolver/ComponentResolver";
const SnippetBoard = ({ data }) => {
  const [showMessage, setShowMessage] = useState(false);
  const [isEmptyObj, setIsEmptyObj] = useState(false);
  useEffect(()=>{
    if(Object.keys(data.snippet).length !==0){
      setIsEmptyObj(true)
    }
  },[])
  return (
    <>
      {showMessage && (
        <SnackBar message={"Code snippet copied!"} variant={"success"} />
      )}
      {isEmptyObj && (
        <>
      <p style={{ fontSize: 18, fontWeight: 600, marginBottom: 5 }}>
        Snippet :-
      </p>
      <div style={{ whiteSpace: "pre" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#343742",
            padding: "0 10px",
          }}
        >
          <p style={{ margin: 0, color: "#9FA3A7" }}>
            {data?.snippet?.type[0]?.toUpperCase() +
              data?.snippet?.type?.slice(1)}
          </p>
          <IconButton
            variant="contained"
            onClick={() => {
              navigator.clipboard.writeText(
                JSON.stringify(data?.snippet, null, "\t")
              );
              setShowMessage(true);
              setTimeout(() => setShowMessage(false), 1500);
            }}
          >
            <ContentCopyIcon style={{ color: "#9FA3A7" }} />
          </IconButton>
        </div>
        <div
          style={{
            backgroundColor: "#333",
            border: "0.5px solid #444444",
            color: "#fff",
            padding: 10,
            fontSize:14
          }}
        >
          {JSON.stringify(data.snippet, null, "\t")}
        </div>
      </div>
      <p style={{ fontSize: 18, fontWeight: 600, marginBottom: 0 }}>
        Preview :-
      </p>
        <ComponentResolver
          pageObject={{ components: [data?.snippet] }}
          spread={true}
        />
        </>
      )}
    </>
  );
};

export default SnippetBoard;
