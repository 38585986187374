import Profit from "../../assets/profit.png";
import Loss from "../../assets/loss.png";
import Text from "../../utils/Text";

const CustomGroceryKpiCard = (props) => {
  const { data } = props;
  return (
    <div
      style={
        data?.outerBodyStyle
          ? data.outerBodyStyle
          : {
              padding: 10,
              border: "1px solid rgba(200, 206, 215,0.5)",
              borderRadius: "3px",
            }
      }
    >
      <Text
        style={
          data?.headerStyle
            ? data.headerStyle
            : {
                marginBottom: 7,
                fontSize: "0.875rem",
                background: "#0055AF",
                borderRadius: "15px",
                width: "max-content",
                padding: "0.25rem 1.25rem",
                color: "white",
              }
        }
        weight="bold"
      >
        {data.header}
      </Text>
      <div
        style={
          data?.rowStyle
            ? data?.rowStyle
            : {
                display: "flex",
                gap: "15%"
              }
        }
      >
        {data.rowData.map((element) => (
          <div
            style={
              element?.itemStyle
                ? element.itemStyle
                : {
                    display: "flex",
                    alignItems: "center",
                    paddingTop: 5,
                    paddingBottom: 5,
                    gap: 5,
                  }
            }
          >
            {element.label && (
              <Text
                style={
                  element?.labelStyle
                    ? element.labelStyle
                    : {
                        fontSize: "0.75rem",
                        minWidth: "max-content",
                        color: "#758498",
                        fontWeight: 500,
                      }
                }
              >
                {element.label}
              </Text>
            )}
            {element.value && (
              <Text
                style={
                  element?.valueStyle
                    ? element.valueStyle
                    : {
                        fontSize: "0.75rem",
                        color: "#0055AF",
                        fontWeight: "600",
                      }
                }
              >
                {element.value}
              </Text>
            )}
            {element.icon && (
              <img
                src={element.icon === "profit" ? `${Profit}` : `${Loss}`}
                style={
                  element?.iconStyle ? element.iconStyle : { width: "1rem" }
                }
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomGroceryKpiCard;
