export const treemapwithlabelOptions = (props) => {
  return {
    plotOptions: {
      treemap: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.point.name + " : " + this.point.value + "%";
          },
        },
        legendType: "point",
        showInLegend: true,
      },
    },
    series: [
      {
        type: "treemap",
        data: props.data,
      },
    ],
    title: {
      text: props.title,
    },
  };
};
