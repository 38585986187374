import React from "react";

const CircularColorChips = (props) => {
  

  return (
    <div
      style={{
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        backgroundColor: props.value,
        display: "inline-block",
        marginRight: "30px",
      }}
    ></div>
  );
};

export default CircularColorChips;
