import { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import useAction from "../../../utils/useAction";
export default function RowExpander(params) {
  const [expand, setExpand] = useState(params.node.expanded);
  const invokeAction = useAction()
  const handleExpand = () => {
    setExpand((prev) => !prev);
  };
  function getArrow(value) {
    return " " + (value ? "\u23f6" : "\u23f7");
  }
  function addChild(params) {
    const lastChildIndex = params.node.allLeafChildren.length - 1;
    invokeAction(params.action, params.node.allLeafChildren[lastChildIndex].data)
  }
  useEffect(() => {
    params.api.setRowNodeExpanded(params.node, expand);
  }, [params, expand]);
  return (
    <div style={{
      display: "flex",
      alignItems:"center"
    }}>
      {params.node.allLeafChildren.length}
      <span onClick={handleExpand}>{getArrow(expand)}</span>
      {params?.addIcon && <AddIcon style={{ marginLeft: "15px",cursor:"pointer" }} onClick={() => addChild(params)} />}
    </div>
  );
}
