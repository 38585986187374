import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import PlaceHolderImage from "../../../assets/placeholder.png";
import { CustomModal } from "./customModal";
import { useNavigate } from "react-router-dom";
const imageStyle = {
  borderRadius: "25px",
  border: "12px solid #E3E7EA",
};
const CustomImage = (props) => {
  const navigate = useNavigate();
  const { data } = props;
  const [open, setOpen] = useState(false);
  const onClick = () => {
    if (data.action.actionName === "modal") {
      setOpen(true);
    }
    if (data.action.actionName === "navigate") {
      navigate(
        "/" +
          window?.location?.pathname?.split("/")[1] +
          "/" +
          window?.location?.pathname?.split("/")[2] +
          props.data.action.path
      );
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {data.uniqueKey === "image" ? (
        <>
          {data.path ? (
            <img
              src={require(`../../../assets/${data.path}`)}
              alt=""
              style={data?.imageStyle}
              onClick={() => {
                onClick();
              }}
            />
          ) : (
            <img src={PlaceHolderImage} alt="" style={data?.imageStyle} />
          )}
          {open ? (
            <CustomModal
              show={open}
              {...data.action}
              handleClose={handleClose}
            />
          ) : null}
        </>
      ) : (
        <Grid container spacing={12}>
          {props.data.data.map((ele) => {
            return (
              <Grid item xs={1}>
                <img
                  style={data?.imageStyle}
                  src={require(`../../../assets/TestingImage/${ele.image}.JPG`)}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};
export default CustomImage;
