import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import { Typography, TextField } from "@mui/material";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import PublishedWithChangesOutlinedIcon from "@mui/icons-material/PublishedWithChangesOutlined";
import TrackChangesOutlinedIcon from "@mui/icons-material/TrackChangesOutlined";
import CustomImage from "./CustomImage";
const CustomPriceSmartGroceryCard = (props) => {
  const iconStyle = {
    fontSize: "3rem",
    borderRadius: "25px",
    backgroundColor: "white",
    color: "#0055AF",
    padding: "10px",
  };
  const { data } = props;
  const IconSelecter = (key, style) => {
    const sx = style || iconStyle;
    switch (key) {
      case "trackChange":
        return <TrackChangesOutlinedIcon style={sx} />;
      case "calculator":
        return <CalculateOutlinedIcon style={sx} />;
      case "published":
        return <PublishedWithChangesOutlinedIcon style={sx} />;
      case "event":
        return <EmojiEventsOutlinedIcon style={sx} />;
    }
  };
  return (
    <div
      style={
        props.parentStyle ? props.parentStyle : { display: "flex", gap: 10 }
      }
    >
      {data.map((element) => {
        return (
          <Grid item lg={2}>
            <Card
              style={
                element.cardStyle
                  ? element.cardStyle
                  : { backgroundColor: "#0055AF", color: "white" }
              }
            >
              <Grid container>
                <Grid item lg={8}>
                  <CardContent>
                    <Typography
                      style={
                        element.headerStyle
                          ? element.headerStyle
                          : { fontSize: "14px" }
                      }
                    >
                      {element.header}
                    </Typography>
                    <Typography
                      style={
                        element.valueStyle
                          ? element.valueStyle
                          : { fontSize: "14px", fontWeight: "600" }
                      }
                    >
                      {element.value}
                    </Typography>
                  </CardContent>
                </Grid>

                <Grid
                  item
                  lg={4}
                  style={
                    data?.subCardStyle
                      ? data.subCardStyle
                      : {
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }
                  }
                >
                  <CardContent
                    style={{
                      padding: "0px",
                    }}
                  >
                    <div style={{ paddingTop: "5px" }}>
                      {element.key ? (
                        IconSelecter(element.key)
                      ) : (
                        <CustomImage data={element.image} />
                      )}
                    </div>
                  </CardContent>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        );
      })}
    </div>
  );
};

export default CustomPriceSmartGroceryCard;
