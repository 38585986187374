import { Checkbox, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import CircleIcon from '@mui/icons-material/Circle';
const useStyles = makeStyles({
  block: {
    margin: 1.5,
    padding: 2,
  },
  red: {
    borderColor: "#DE3740",
    backgroundColor: "#FCE9EA",
    color: "#DE3740",
  },
  green: {
    borderColor: "#40AD5F",
    backgroundColor: "#E8F5EC",
    color: "#40AD5F",
  },
  orange: {
    borderColor: "#40AD5F",
    backgroundColor: "#FFD9BF",
    color: "#FF832B",
  },
  grey: {
    backgroundColor: "#808080",
    color: "#FEF6EB",
  },
  aqua: {
    backgroundColor: "#34BFA3",
    color: "#FEF6EB",
  },
  lightblue: {
    backgroundColor: "#B1BEFF",
    color: "#FEF6EB",
  },
  blue: {
    backgroundColor: "#7CB5EC",
    color: "#FEF6EB",
  },
  lightred: {
    backgroundColor: "#EF5D5D",
    color: "#FEF6EB",
  },
  flexContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  bullet: {
    fontSize: 30,
  },
  legendItem: {
    display: "flex",
    gap: 2,
    alignItems: "center",
  },
  heading: {
    color: "#44677B",
    margin: 2,
    padding: 5,
    fontSize: 18,
    textAlign: "center",
  },
});

const BoxAligner = (props) => {
  switch (props) {
    case "left":
      return "flex-start";
    case "center":
      return "center";
    case "right":
      return "flex-end";
    default:
      return "center";
  }
};

const DataChart = (props) => {
  const { selectedCheckbox} = useSelector(
    (state) => state.home
  );
  const { info, legendPosition, legends, title, actionKey } = props.data;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [checkStatus, setCheckStatus] = useState(0);
  const handleCheckboxChange = (item, value) => {
    const data = {
      item: item,
      value: value,
    };
    dispatch({
      type: "UPDATE_SELECTED_CHECKBOX",
      payload: data,
    });
    if (actionKey) {
      setCheckStatus((prev) => (value ? prev + 1 : prev - 1));
    }
  };
  useEffect(() => {
    if (checkStatus === 0 || checkStatus === 1) {
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: { [actionKey]: checkStatus === 0 ? false : true },
      });
    }
  }, [checkStatus]);
  return (
    <>
      {/* CHART  */}
      {title && <Typography className={classes.heading}> {title}</Typography>}
      <Box className={classes.flexContainer} sx={{fontWeight: "bold",fontSize:"14px"}}>
        {info &&
          info.map((item) => {
            return (
              <>
                {item?.label ? (
                  <Box
                    className={
                      classes.block + " " + classes[`${item.colorType}`]
                    }
                    sx={item.blockStyle ? item.blockStyle : {
                      width: item?.width,
                      height: item?.height || 100,
                      borderColor: item?.styles?.color,
                      color: item?.styles?.color,
                      background: item?.styles?.backgroundColor,
                      borderWidth: item?.styles?.borderSize || 2,
                      borderStyle: item?.styles?.borderType || "solid"
                    }}
                    value={item?.label}
                    onClick={(e)=>{
                      if(props?.data?.uniqueKey === "toggle-event"){
                        handleCheckboxChange(
                          e.target.value,
                          1,
                        );
                      }
                    }}
                  >
                    {item?.hasCheckbox && (
                      <Checkbox
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: item.checkboxSize || 15,
                          },
                        }}
                        defaultChecked={item.defaultChecked || false}
                        value={item?.label}
                        onChange={(e) => {
                          handleCheckboxChange(
                            e.target.value,
                            e.target.checked,
                          );
                        }}
                      />
                    )}
                    {item.label}
                  </Box>
                ) : (
                  <Box
                    className={classes.flexContainer}
                    sx={{ width: item?.rowWidth }}
                  >
                    {item.row.map((element) => {
                      return (
                        <>
                          <Box
                            className={
                              classes[`${element.colorType}`] +
                              " " +
                              classes.block
                            }
                            sx={element.blockStyle ? element.blockStyle : {
                              width: element?.width,
                              height: element.height || 100,
                              borderColor: element?.styles?.color,
                              color: element?.styles?.color,
                              background: element?.styles?.backgroundColor,
                              borderWidth: element?.styles?.borderSize || 2,
                              borderStyle: element?.styles?.borderType || "solid"
                            }}
                            value={item?.label}
                            onClick={(e)=>{
                              if(props?.data?.uniqueKey === "toggle-event"){
                                handleCheckboxChange(
                                  e.target.value,
                                  1,
                                );
                              }
                            }}
                          >
                            {element?.hasCheckbox && (
                              <Checkbox
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: element.checkboxSize || 15,
                                  },
                                }}
                                defaultChecked={element.defaultChecked || false}
                                value={element?.label}
                                onChange={(e) => {
                                  handleCheckboxChange(
                                    e.target.value,
                                    e.target.checked
                                  );
                                }}
                              />
                            )}
                            {element.label}
                          </Box>
                        </>
                      );
                    })}
                  </Box>
                )}
              </>
            );
          })}
      </Box>

      {/* LABEL LEGEND  */}
      {legends && (
        <Box
          sx={{
            margin: 2,
            justifyContent: BoxAligner(legendPosition),
            display: "flex",
            paddingBottom: 1,
            gap: "2%",
          }}
        >
          {legends.map((item) => {
            return (
              <>
                <Box className={classes.legendItem}>
                    <CircleIcon sx={{color: item.color || "#40AD5F", fontSize: item.bulletSize || 20}} />  
                  <Typography sx={(item?.textStyle) ? item.textStyle : { color: item.color || "#40AD5F", fontSize: item.fontSize || '0.875rem'}}>
                    {item.text}
                  </Typography>
                </Box>
              </>
            );
          })}
        </Box>
      )}
    </>
  );
};

export default DataChart;
