import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import { store } from "./redux/store";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
	<React.Fragment>
		<ToastContainer position={toast.POSITION.TOP_RIGHT} />
		<Provider store={store}>
			<App />
		</Provider>
	</React.Fragment>,
	document.getElementById("roottest"),
);
