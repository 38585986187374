import Cookies from "js-cookie";

export const getChatResponse = async (val, token) => {
  try {
    var requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        prompt: val,
      }),
      redirect: "follow",
    };
    const request = await fetch(
      "https://signet.devs.impactsmartsuite.com/api/v2/inventory-smart/gpt/query",
      requestOptions
    );
    const response = await request.json();
    return {
      response: response?.data,
      status: true,
    };
  } catch (err) {
    return {
      status: false,
      response: err
    };
  }
};

export const getAuthToken = async () => {
  var myHeaders = new Headers();
  myHeaders.append("accept", "*/*");
  myHeaders.append("accept-language", "en-GB,en-US;q=0.9,en;q=0.8");
  myHeaders.append("content-type", "application/x-www-form-urlencoded");
  myHeaders.append("origin", "https://signet.devs.impactsmartsuite.com");
  myHeaders.append("referer", "https://signet.devs.impactsmartsuite.com/");
  myHeaders.append(
    "sec-ch-ua",
    '"Google Chrome";v="123", "Not:A-Brand";v="8", "Chromium";v="123"'
  );
  myHeaders.append("sec-ch-ua-mobile", "?0");
  myHeaders.append("sec-ch-ua-platform", '"macOS"');
  myHeaders.append("sec-fetch-dest", "empty");
  myHeaders.append("sec-fetch-mode", "cors");
  myHeaders.append("sec-fetch-site", "cross-site");
  myHeaders.append(
    "user-agent",
    "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36"
  );
  myHeaders.append(
    "x-client-data",
    "CIa2yQEIpLbJAQipncoBCK/pygEIkqHLAQib/swBCIagzQEIyf3NARjAy8wBGNiGzgE="
  );
  myHeaders.append("x-client-version", "Chrome/JsCore/8.10.1/FirebaseCore-web");

  var urlencoded = new URLSearchParams();
  urlencoded.append("grant_type", "refresh_token");
  urlencoded.append(
    "refresh_token",
    "AMf-vBxY7cWGpF5Z4HykCgNhxoA_3VwHsR2_6FbmR1huKTCBOh6Eclcdmvd4G5Qbi3OqMMV7cotD8hSN5f0UiFwwG1NSCrqV6c8VBb1_Y3DSSThE5ffkDrzIBz1SZVjbA-m10L1ovXTzrMqjZ8tyo0i5I89WWAMp-_UO-0d4EO5_t4P3hQYTxeYKE6-v3At93ZMJ5qsGagm_pZN2yffKPihj1ZOpdJfnIN1q0MgEUF5Sx61w1-9dCDU"
  );

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  try {
    const request = await fetch(
      "https://securetoken.googleapis.com/v1/token?key=AIzaSyDAZfJzJ57rMOyAgmLCgSc3L1NkpnKdFVY",
      requestOptions
    );
    const response = await request.json();
    return {
      response,
      status: true,
    };
  } catch (error) {
    return {
      response: error,
      status: false,
    };
  }
};

export function updateMessage(responseMsg) {
  switch (responseMsg.type) {
    case "steps": {
      const newSteps = responseMsg.message.steps.map((text) => {
        return {
          text,
          complete: false,
        };
      });
      return {
        ...responseMsg,
        message: {
          ...responseMsg.message,
          steps: newSteps,
        },
      };
    }
    default:
      return responseMsg;
  }
}