import callApi from "../utils/callApi";
import apiJson from "../utils/clientsList";

export const getClientAndProductNames = ()=>{
	const pathDetails = window?.location?.pathname?.split("/")
	// const client_name = pathDetails[1] === 'dev' ? apiJson[`${pathDetails[1]}`]["client_name"] : pathDetails[1]
	// const product_name = pathDetails[1] === 'dev' ? apiJson[`${pathDetails[1]}`]["product_name"] : pathDetails[2]
	const client_name =  pathDetails[1]
	const product_name = pathDetails[2]
	return {
		client_name,
		product_name
	}
}
export const getApiCall = (url,reqParams)=>{
	const { product_name,client_name } = getClientAndProductNames()
	if(product_name && client_name){
		return callApi.get(url,{params: {...reqParams,client_name,product_name}})
		.then((res) => {
			return res;
		})
		.catch((err) => {
			return err;
		});
	}
	
}

export const postApiCall = (url,reqParams)=>{
	const { product_name,client_name } = getClientAndProductNames()
	if(product_name && client_name){
		return callApi.post(url,{...reqParams,client_name,product_name})
		.then((res) => {
			return res;
		})
		.catch((err) => {
			return err;
		});
	}
	
}

export const fetchHierarchyData = (filterProperty, reqObj) => {
	let url = encodeURIComponent(filterProperty);
	return postApiCall('get_data_model',{model_names: [url]})
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return err;
		});
};

export const getMultipleModelsData = (reqParams) => {
	return postApiCall('get_mutliple_data_models',reqParams).then((res) => {
			return res.data;
		})
		.catch((err) => {
			return err;
		});
};
