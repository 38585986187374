import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";

function getIcon(type) {
  switch (type) {
    case "warehouse":
      return <WarehouseOutlinedIcon fontSize="small" />;
    default:
      return <></>;
  }
}
function Node({ data }) {
  const bgColor = data.color;
  return (
    <div className="archer-node" style={{ backgroundColor: bgColor }}>
      {data.label}
    </div>
  );
}

function Scrollbox({ data }) {
  const { list } = data;
  const seq = list.map((label) => {
    return (
      <li style={{ display: "flex", alignItems: "center", gap: 10 }}>
        {getIcon(data?.icon)}
        <span>{label}</span>
      </li>
    );
  });
  return (
    <div className="archer-scrollbox">
      <ul>{seq}</ul>
    </div>
  );
}

export { Node, Scrollbox };
