import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomCard from "../../../components/CustomCard";
import moment from "moment";
import DynamicForms from "./index";
import { isArray } from "highcharts";
import { fetchFormDataAction, getMultipleModelsData } from "../action";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const styles = (theme) => ({
  headerCard: {
    ...theme.content.card,
    width: "100%",
    height: "100%",
    overflow: theme.content.overflow.visible,
  },
  hierarchyHeaderCard: {
    ...theme.content.card,
    width: "100%",
    height: "100%",
    padding: "1%",
    display: "flex",
    justifyContent: "space-between",
    overflow: theme.content.overflow.visible,
  },
  hierarchyCard: {
    ...theme.content.card,
    maxWidth: 280,
    minWidth: 280,
    height: "100%",
    padding: "2%",
    overflow: theme.content.overflow.visible,
  },
  filterPage: {
    ...theme.content.card,
    width: "100%",
    height: "30vh",
    paddingTop: "0.75%",
    overflow: theme.content.overflow.visible,
  },
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
  },
  formContainer: {
    // backgroundColor: 'white'
  },
});
const useStyles = makeStyles(styles);
const FormsList = (props) => {
  const { screenName, forms, form_label, filter_id } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { formMenuData, formMasterValuesData } = useSelector(
    (state) => state.filter
  );
  const [dynamicFormsList, setDynamicFormsList] = useState([]);
  const [dynamicFormsOptions, setDynamicFormsOptions] = useState([]);
  const [formsMenu, setFormsMenu] = useState([]);
  const [formMasterValues, setFormMasterValues] = useState([]);
  const [filterData, setFilterData] = useState({});
  useEffect(() => {
    if (formMenuData.length === 0) {
      dispatch(
        fetchFormDataAction({
          model_names: ["tb_form_master", "tb_form_master_values"],
        })
      );
    }
  }, []);
  useEffect(() => {
    const filter_data = formMenuData.filter(
      (obj) => parseInt(obj["filter_id"]) === filter_id
    );
    setFormsMenu(filter_data);
    setFormMasterValues(formMasterValuesData);
  }, [formMenuData, formMasterValuesData, form_label]);

  const reloadFilters = async () => {
    const filterObj = {};
    await formsMenu.forEach((obj) => {
      if (obj.type === "datetime" || obj.type === "date-range") {
        filterObj[`${obj.label}`] = [
          new Date(moment().subtract(1, "years")),
          new Date(moment()),
        ];
      } else if (obj.type === "date") {
        filterObj[`${obj.label}`] = obj.start_date ? [new Date(moment(obj.start_date))] : null ;
      } else {
        if (["single-select", "multi-select"].includes(obj.type)) {
          const newData = formMasterValues.filter(
            (childObj) =>
              parseInt(childObj.parent_id) === parseInt(obj.categoryid) &&
              childObj.is_default_value === "Y"
          );
          newData.filter((obj) => {
            obj["label"] = obj.name;
            obj["value"] = obj.name;
          });
          filterObj[`${obj.master_category}`] = newData;
        } else if (["checkboxes", "radio"].includes(obj.type)) {
          filterObj[`${obj.label}`] = obj.value;
        } else if (obj.value) {
          filterObj[`${obj.label}`] = obj.value;
        } else {
          filterObj[`${obj.label}`] = null;
        }
      }
    });
    setFilterData(filterObj);
  };
  useEffect(() => {
    reloadFilters();
  }, [formMasterValues]);
  const getFilterOptions = (categoryid) => {
    let newData = formMasterValues.filter(
      (obj) => obj.parent_id === categoryid
    );
    newData.filter((obj) => {
      obj["label"] = obj.name;
      obj["value"] = obj.name;
    });
    return newData;
  };
  useEffect(() => {
    if (Object.keys(filterData).length > 0) {
      const filtersPropsList = [];
      const filtersOptionsList = {};
      formsMenu.forEach((obj) => {
        filtersPropsList.push({
          type: obj.type,
          placeholder: `Select ${obj.label}`,
          state: filterData[`${obj.label}`],
          mappingKey: obj.label,
          label: obj.is_indicator_required === "FALSE" ? "" : obj.label,
          required: obj.is_required === "TRUE",
          id: obj.categoryid,
          infotipEnabled: obj.infotip_enabled === "1",
          infotip: obj.infotip,
          width: obj.width,
          disabled: obj.isDisabled === "TRUE" ? true : false,
          grid: obj.grid,
          defaultValue: obj.default_value,
          ...obj,
        });
        filtersOptionsList[`${obj.label}Options`] = getFilterOptions(
          obj.categoryid
        );
      });
      setDynamicFormsList(filtersPropsList);
      setDynamicFormsOptions(filtersOptionsList);
    }
  }, [filterData]);

  const updateState = (params) => {
    let reqParams = {
      ...filterData,
      ...params,
    };
    setFilterData(reqParams);
    props.updateParentState(reqParams);
  };
  const onReset = () => {
    reloadFilters();
    props.onReset();
  };
  const appliedFiltersAction = async () => {
    props.appliedFilters(filterData);
    let reqObj = {};
    Object.keys(filterData).filter((obj, index) => {
      if (filterData[`${obj}`].length > 0) {
        reqObj[`${obj}`] = {
          label: obj,
          section: filterData[`${obj}`].map((obj) => obj.value),
        };
      }
    });
  };

  const requestToStore = (type, params) => {
    switch (type) {
      case "formEditedValues":
        dispatch({
          type: "FORM_EDITED_VALUES",
          payload: params,
        });
        break;
      case "dependentValues":
          dispatch({
            type: "DEPENDENT_COMPONENTS",
            payload: params,
          });
          break;
      default:
        break;
    }
  };
  return (
    <Grid>
      <Grid>
        {props.isTopFilters ? (
          <>
            <CustomCard cardStyles={classes.filterPage}>
              <div className="filter-container">
                <h2>{form_label}</h2>
                {dynamicFormsList.length > 0 && (
                  <DynamicForms
                    forms={dynamicFormsList}
                    updateParentState={updateState}
                    appliedFilters={appliedFiltersAction}
                    onReset={onReset}
                    screenName={screenName}
                    options={dynamicFormsOptions}
                    isSubmitRequired={props.isSubmitRequired}
                    submitButtonName={props.submitButtonName}
                    resetName={props.resetName}
                    horizontalView={props.horizontalView}
                    onButtonClick={props.onButtonClick}
                  />
                )}
              </div>
            </CustomCard>
          </>
        ) : (
          <>
            {/* <Card> */}
            <div className={classes.formContainer}>
              {form_label && <h4>{form_label}</h4>}
              {dynamicFormsList.length > 0 && (
                <DynamicForms
                  forms={dynamicFormsList}
                  updateParentState={updateState}
                  appliedFilters={appliedFiltersAction}
                  onReset={onReset}
                  screenName={screenName}
                  options={dynamicFormsOptions}
                  isSubmitRequired={props.isSubmitRequired}
                  onLabelActionClick={props.onLabelActionClick}
                  submitButtonName={props.submitButtonName}
                  resetName={props.resetName}
                  horizontalView={props.horizontalView}
                  isStoreRequired={props.isStoreRequired}
                  requestToStore={requestToStore}
                  onButtonClick={props.onButtonClick}
                />
              )}
              <div className="spacer"></div>
              <div className="spacer"></div>
            </div>
            {/* </Card> */}
          </>
        )}
      </Grid>
    </Grid>
  );
};
export default FormsList;
