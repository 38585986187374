import React from "react";
import "./style.css";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import AlignHorizontalLeftOutlinedIcon from "@mui/icons-material/AlignHorizontalLeftOutlined";
import PercentLine from "../../assets/svg/percent.svg";
import ChartLine from "../../assets/svg/chart-line.svg";
import ChartBellCurve from "../../assets/svg/chart-bell-curve.svg";



const returnIcon = (icon) => {
    switch(icon){
        case 'percent-line':
            return <img src={PercentLine} />
        case 'chart-line':
            return <img src={ChartLine} />
        case 'chart-bell-curve':
            return <img src={ChartBellCurve} />
        default:
            return ""
    }
}

export default function ForecastKpiCard({ data }) {
  console.log("]]]]", data);
  return (
    <div className="forecastKpiCard">
      {data?.components.map((item) => {
        return (
          <div className={`forecast-container ${item.border? 'border':null}`}>
            <div className="forecast-icon-container" style={{ backgroundColor: item.iconBGColor }}>
              {/* <AlignHorizontalLeftOutlinedIcon style={{ color: item.iconColor }}/> */}
             {returnIcon(item.icon)}
            </div>
            <div className="forecast-label-container">
              <div className="forecast-label">{item.label}</div>
              <div className="forecast-values">
                <div className="forecast-main-values">{item.mainValue}</div>
                <div className={`forecast-profit-values ${item.profitType === "profit"? "profit" :"loss"}`}>
                  {item.profitValue}
                </div>
                <div className="forecast-profit-icon">
                  {item.profitType === "profit" ? (
                    <TrendingUpIcon style={{ color: "#24a148" }}/>
                  ) : (
                    <TrendingDownIcon style={{ color: "#DA1E28" }}/>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
