export const lineChartOptions = (props) => {
  return {
    chart: {
      type: "line",
      height: props.height,
      spacing: [20, 10, 10, 5],
      backgroundColor: "#F5F8FA",
      plotBackgroundColor: "#ffffff",
      ignoreHiddenSeries: false,
    },
    title: {
      text: props.title,
    },
    yAxis: {
      title: {
        text: props.yaxis.title,
        style: {
          color: "#44677b",
          fontSize: "0.7rem",
          fontWeight: "600",
        },
      },
      labels: {
        style: {
          color: "#44677b",
          fontSize: "0.6rem",
        },
      },
      tickAmount: props.yaxis.tickAmount,
      gridLineColor: "#e9e9e9",
      categories: props.yaxis.categories,
    },
    xAxis: {
      title: {
        text: props.xaxis.title,
        style: {
          color: "#44677b",
          fontSize: "0.7rem",
          fontWeight: "500",
        },
      },
      accessibility: {
        rangeDescription: props.xaxis.title,
      },
      labels: {
        style: {
          color: "#44677b",
          fontSize: "0.6rem",
        },
      },
      categories: props.xaxis.categories,
      plotLines: props.xaxis.plotLines,
      plotBands: props.xaxis.plotBands
    },
    legend: {
      enabled: props.showLegend,
      //layout: 'horizontal',
      layout: props.layout || "horizontal",
      align: props.legendAlign || "center",
      verticalAlign: props.legendVerticalAlign || "bottom", //"bottom", //"middle",
      itemStyle: {
        color: "#44677b",
        fontSize: "0.7rem",
        fontWeight: "600",
        margin: "10px",
      },
      itemCheckboxStyle: {
        position: "absolute",
        width: "12px",
        height: "12px",
      },
      padding: 0,
      itemMarginTop: 4,
      itemMarginBottom: 2,
      ...props.legend,
    },
    tooltip: {
      enabled: true,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
        },
        lineWidth: props.lineWidth || 1.5,
        pointStart: 0
      },
    },
    series: props.data,
    credits: {
      enabled: false,
    },
  };
};
