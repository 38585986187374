import moment from "moment";
export default function TemporalProvider({ data }) {
    const temporalInfo = data.info;
    function getTemporal() {
        switch (temporalInfo.type) {
            case 'date': {
                return moment().format(temporalInfo.format || 'YYYY-MM-DD')
            }
            default:
                return <></>
        }
    }
    return <div>
        {getTemporal()}
    </div>
}