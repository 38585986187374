import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Box } from "@mui/material";
import L from "leaflet";

const customIcon = new L.Icon({
  iconUrl: require("../../assets/images/locationIcon.png"),
  iconSize: [20, 20],
});

const CustomMap = (props) => {
  const { info } = props.info;
  return (
    <>
      <Box sx={{
        display:"flex",
        justifyContent:"center",
        ...info?.mapWrapperStyle
        }}>
        <MapContainer
          center={info?.mapCenter || [39.82, -98.58]}
          zoom={info?.mapDefaultZoom || 5}
          style={{
            height: info?.mapHeight || "50vh",
            width: info?.mapWidth || "50%",
            ...info?.mapStyle
          }}
        >
          <TileLayer
            url={"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"}
          />
          {/* <TileLayer url={"http://{s}.tile2.opencyclemap.org/transport/{z}/{x}/{y}.png"} /> */}
          {info?.markers.length > 0 &&
            info?.markers?.map((element, index) => {
              return (
                <>
                  <Marker position={element?.markerPosition} icon={customIcon} key={"mapMarker"+index}>
                    {element?.popupContent && <Popup>{element?.popupContent}</Popup>}
                  </Marker>
                </>
              );
            })}
        </MapContainer>
      </Box>
    </>
  );
};

export default CustomMap;
