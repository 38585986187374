import { useSelector } from "react-redux";
const funcs = {
  "*": (a, b) => a * b,
};

const convertToUsAccountingFormat = (number1) => {
  let numberToString = number1.toString();
  let len = numberToString.length;
  if (len === 0 || Number.isNaN(number1)) {
    return "";
  } else {
    let count = 0;
    let result = "";
    for (let i = len - 1; i >= 0; i--) {
      result = numberToString[i] + result;
      count++;
      if (count % 3 === 0 && count < len) {
        result = "," + result;
      }
    }
    return result;
  }
};

export default function CellCompute(params) {
  const { tableInfo } = useSelector((state) => state.home);
  const rows = tableInfo[params.parentTableKey];
  const field = params.colDef.field;
  const a = parseFloat(
    rows.find((row) => row.id == params.seq[0])[field]?.replace(/,|%|\$|€/g, "")
  );
  const b = parseFloat(
    rows.find((row) => row.id == params.seq[2])[field]?.replace(/,|%|\$|€/g, "")
  );
  const output = funcs[params.seq[1]](a, b);
  const formattedOutput = convertToUsAccountingFormat(Math.trunc(output));
  return formattedOutput;
}
