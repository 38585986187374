import React from "react";
import { Grid, Card } from "@mui/material";

const ColorKPICardComponent = (props) => {


  return (
    <Grid container spacing={-5}>
      <div>
        {props.data.data.map((elem) => {
          return (
            <div
              style={{
                display: "inline-block",
                marginRight: "10px",
                marginBottom: "10px",
              }}
            >
              <Grid item xs={12}>
                <Card
                  style={{
                    backgroundColor: elem.color,
                    width: "150px",
                    height: "40px",
                    display: "flex",
                    color:"white",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {elem.value}
                </Card>
                <h6 style={{ textAlign: "center" }}>{elem.header}</h6>
              </Grid>
            </div>
          );
        })}
      </div>
    </Grid>
  );
};

export default ColorKPICardComponent;
